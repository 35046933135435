//Axios Template
import callAPI from "../../AxiosHelper/APICall";

//Constants
import { URLS } from "../../AxiosHelper/ApiUrls";
import { METHOD } from "../../Constants/AppConstants";

//Services
import proConfService from "../../Services/ProConfService";

export async function getAdminBackendDetails() {
    try {
        // Call the API function to get the admin backend details
        const response = await
            callAPI({
                method: METHOD.HTTP.GET,
                url: URLS.ADMIN_DETAILS,
            })

        // @ts-ignore
        return response?.response?.data;

    } catch (error) {
        console.log("getAdminBackendDetails error: ", error);
    }
}

export async function getWebSDKDetails() {
    const sdkVersion = await proConfService?.getSDKVersion()
    console.log('sdkVersion: ', sdkVersion);
    return sdkVersion;
}