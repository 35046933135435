import { FunctionComponent } from "react";
import "./RecordingOptions.scss";
import radio2 from "../../Resources/InCallImages/radio2.svg";

export type RecordingOptionsType = {
  className?: string;
  icon?: string;
  label?: string;
  onClick?: () => void;
};

const RecordingOptions: FunctionComponent<RecordingOptionsType> = ({
  className = "",
  icon,
  label,
  onClick,
}) => {
  return (
    <button className={`stopdefault2 ${className}`} onClick={onClick}>
      <div className="checkbox22" />
      <img className="radio2-icon2" alt="" src={radio2} />
      <img className="menuicon7" alt="TranscriptMenuIcon" src={icon} />
      <div className="transcript2">{label}</div>
    </button>
  );
};

export default RecordingOptions;
