import { FunctionComponent, useState } from "react";
import "./ParticipantsPanel.scss";
import PanelTitleBar from "../PanelTitleBar/PanelTitleBar";
import PrimaryButtonSmall from "../PrimaryButtonSmall/PrimaryButtonSmall";
import SecondaryButtonSmall from "../SecondaryButtonSmall/SecondaryButtonSmall";
// import WaitingParticipant from "../WaitingParticipant/WaitingParticipant";
// import TertiaryButton from "../TertiaryButton/TertiaryButton";
import InCallParticipant from "../InCallParticipant/InCallParticipant";
import { getTranslation } from "../../Resources/Localization/i18n";
import { useSelector } from "react-redux";
// import { CONFIGURATIONS } from "../../Constants/AppConstants";

export type ParticipantsPanelType = {
  className?: string;
  toggleParticipantsPanel?: () => void;
  setInvitePopup: (value: boolean) => void;
};

const ParticipantsPanel: FunctionComponent<ParticipantsPanelType> = ({
  className = "",
  toggleParticipantsPanel,
  setInvitePopup,
}) => {
  const participants = useSelector(
    (state: any) => state.ParticipantReducer.participants
  );
  const localParticipant = useSelector(
    (state: any) => state.ParticipantReducer.localParticipant
  );
  const { roomName, roomId } = useSelector((state: any) => state.RoomReducer);

  // const { configurations } = useSelector((state: any) => state.LoginReducer);

  // Invite link
  const link =
    process.env.REACT_APP_BASE_URL + "/" + roomId + "?room_name=" + roomName;
  const [copied, setCopied] = useState(false);

  console.log("Participants in participants panel: ", participants);

  // Copy meeting link to clipboard
  const handleCopy = (e: any) => {
    //@ts-ignore
    // navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
    //   if (result.state === "granted" || result.state === "prompt") {
    /* write to the clipboard now */
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((err) => console.error("Failed to copy: ", err));
    // } else {
    //   console.error('Clipboard write permission denied.');
    // }
    //});
  };
  return (
    <div className={`participantspanel ${className}`} id="participantspanel">
      <PanelTitleBar
        titleText={getTranslation("participants")}
        toggleButton={false}
        dropdown={false}
        onClickCloseIcon={toggleParticipantsPanel}
      />
      <div className="participantscontainer">
        {localParticipant?.isModerator && (
          <div className="participantsbuttoncontainer">
            <PrimaryButtonSmall onClick={() => setInvitePopup(true)} />
            <SecondaryButtonSmall
              buttonLabel={
                copied
                  ? getTranslation("copiedLink")
                  : getTranslation("copyLink")
              }
              onClick={(e: any) => handleCopy(e)}
            />
          </div>
        )}
        <div className="participantspanelbody">
          {/* waiting list 
          {CONFIGURATIONS.WAITING_ROOM in configurations && (
            <div className="waitinglistcontainer">
              <div className="waitingtitlebar">
                <div className="waitingtitle">{getTranslation("waiting")}</div>
                <div className="waitingtitle">(2)</div>
              </div>
              <div className="waitingparticipants">
                <WaitingParticipant />
              </div>
            </div>
          )}
         */}

          <div className="incalllistcontainer">
            <div className="incalltitlebar">
              <div className="incalltitleandcount">
                <div className="waitingtitle">{getTranslation("inCall")}</div>
                <div className="waitingtitle">
                  {"(" + (participants?.length || 0) + ")"}
                </div>
              </div>
              {/*
              {CONFIGURATIONS.MODERATOR_CONTROL in configurations && (
                <TertiaryButton />
              )}
              */}
            </div>
            <div className="incallparticpantlist">
              {participants.length > 0 &&
                participants?.map((participant: any) => (
                  <InCallParticipant
                    key={participant?.sid}
                    showMicStatus={
                      participant?.audioTracks &&
                      participant?.audioTracks[0]?.isEnabled
                        ? true
                        : false
                    }
                    showVideoStatus={
                      participant?.videoTracks &&
                      participant?.videoTracks[0]?.isEnabled
                        ? true
                        : false
                    }
                    participantName={participant?.identity}
                    participantRole={
                      participant?.isModerator
                        ? getTranslation("moderator")
                        : ""
                    }
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantsPanel;
