import { FunctionComponent, useEffect, useMemo, useState } from "react";

//store
import { store } from "../../Redux/store";
import { useSelector } from "react-redux";
import proConfService from "../../Services/ProConfService";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/RouteConstants";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";
import InviteViaEmailPopUp from "../InviteParticipant/InviteViaEmailPopup";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";
import CallScreen from "./CallScreen";
import { ON_TRANSCRIPT_ENABLED } from "../../Redux/ReduxConstants";
import { setSuccessNotification } from "../../Redux/Actions/NotificationAction";
import { getTranslation } from "../../Resources/Localization/i18n";

const CallScreenContainer: FunctionComponent = () => {
  const roomId = store.getState().RoomReducer.roomId;
  const [openInvitePopup, setOpenInvitePopup] = useState(false);

  const isGuest = store.getState()?.RoomReducer.userName ?? false;
  const isCallInProgress = useSelector(
    (state: any) => state?.RoomReducer?.callInProgress
  );

  const username = isGuest
    ? store.getState()?.RoomReducer.userName
    : store.getState()?.LoginReducer?.userName;
  const roomname = store.getState()?.RoomReducer?.roomName;
  const isRoomEnded = useSelector(
    (state: any) => state.InCallReducer?.roomEnded
  );
  const transcript = useSelector(
    (state: any) => state.ToolbarReducer.transcriptionEnabled
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  //react-hooks/exhaustive-deps
  useEffect(() => {
    // Device orientation change
    window.addEventListener("orientationchange", () => adjustDivSize());
    // Also handle window resize events
    window.addEventListener("resize", () => adjustDivSize());

    if (isCallInProgress === false) {
      store.dispatch({
        type: "CALL_IN_PROGRESS",
        payload: true,
      });
    } else {
      handlePageReload({});
    }
    return () => {
      // unmount
      proConfService.clearTracks();
      store.dispatch({ type: "CLEAR_PARTICIPANTS" });
      store.dispatch({ type: "CLEAR_ROOM" });
      store.dispatch({ type: "RESET_TOOLBAR_STATE" });
      store.dispatch({ type: "SET_REMOTE_SS", payload: false });
      window.removeEventListener("resize", () => adjustDivSize());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if (isRoomEnded === true) {
      if (isGuest) {
        navigate(ROUTES.ROOT_PATH);
      } else {
        navigate(ROUTES.MEETING_LIST);
      }
    }
  }, [isGuest, isRoomEnded, navigate]);

  useEffect(() => {
    // When moderator ends the meeting for all, show spinner until we receive event
    if (proConfService.isCallInProgress === false) {
      setIsLoading(true);
    }
  }, [proConfService.isCallInProgress]);

  // Rejoin user
  const rejoinParticipant = async () => {
    if (roomname) {
      let roomId = store?.getState()?.RoomReducer?.roomId;
      // Set transcription toggle as OFF here, as we donot receive transcription disabled event on refresh
      if (transcript) {
        store.dispatch({ type: ON_TRANSCRIPT_ENABLED });
        store.dispatch({
          type: "SET_TRANSCRIPTION_STATE",
          payload: false,
        });
        setSuccessNotification(getTranslation("transcriptStopped"));
      }

      await proConfService
        .joinMeeting(roomname, roomId, username, true)
        .then((room) => {
          if (room) {
            setIsLoading(false);
            navigate(ROUTES.IN_CALL);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  };

  /*
   * Handle page reload when call is in progress
   *(wait for 5 seconds until init is successful)
   */
  const handlePageReload = async (event: any) => {
    try {
      store.dispatch({ type: "CLEAR_PARTICIPANTS" });
      if (store.getState()?.ToolbarReducer.screenShareStarted) {
        store.dispatch({ type: "ON_SCREEN_SHARE_TOGGLE" });
      }
      setIsLoading(true);
      setTimeout(async () => {
        if (!store.getState()?.ProConfReducer.isInitDone) {
          proConfService.initProConf();
          rejoinParticipant();
        } else {
          rejoinParticipant();
        }
        proConfService.handleMediaEvents();
      }, 5000);
    } catch (error) {
      setIsLoading(false);
      window.history.back();
    }
  };

  // Code to handle orientation changes for shared screen UI
  function adjustDivSize() {
    const mydiv = document.getElementById("screen-div");
    if (mydiv) {
      if (window.innerHeight > window.innerWidth) {
        // Portrait mode
        mydiv.classList.remove("landscape-screen");
        mydiv.classList.add("portrait-screen");
      } else {
        // Landscape mode
        mydiv.classList.remove("portrait-screen");
        mydiv.classList.add("landscape-screen");
      }
    }
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <CallScreen />
      {openInvitePopup && (
        <PortalPopup placement="Centered">
          <InviteViaEmailPopUp setInvitePopup={setOpenInvitePopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default CallScreenContainer;
