import { FunctionComponent, useEffect } from "react";
// import "antd/dist/reset.css";
// import { Switch } from "antd";

import "./MobileActionBar.scss";
import ToolBarButton from "../ToolBarButton/ToolBarButton";
// import vector from "../../Resources/InCallImages/vector.svg";
// import ChatOffMediaControlIcon7 from "../../Resources/InCallImages/ChatOffMediaControlIcon7.svg";
// import ChatOffMediaControlIcon2 from "../../Resources/InCallImages/ChatOffMediaControlIcon2.svg";
// import VideoOffMediaControlIcon1 from "../../Resources/InCallImages/VideoOffMediaControlIcon1.svg";
// import ChatOffMediaControlIcon6 from "../../Resources/InCallImages/ChatOffMediaControlIcon6.svg";
// import VideoOffMediaControlIcon4 from "../../Resources/InCallImages/VideoOffMediaControlIcon4.svg";
import ClosePopupIcon from "../../Resources/InCallImages/ClosePopupIcon.svg";
import { ReactComponent as ChatOffMediaControlIcon } from "../../Resources/InCallImages/ChatOffMediaControlIcon.svg";
import { ReactComponent as VideoOffMediaControlIcon } from "../../Resources/InCallImages/VideoOffMediaControlIcon.svg";
import { ReactComponent as ChatOffMediaControlIcon4 } from "../../Resources/InCallImages/ChatOffMediaControlIcon4.svg";
import { ReactComponent as VideoOffMediaControlIcon2 } from "../../Resources/InCallImages/VideoOffMediaControlIcon2.svg";
import { ReactComponent as ChatOffMediaControlIcon5 } from "../../Resources/InCallImages/ChatOffMediaControlIcon5.svg";
import { ReactComponent as VideoOffMediaControlIcon3 } from "../../Resources/InCallImages/VideoOffMediaControlIcon3.svg";
import { getTranslation } from "../../Resources/Localization/i18n";
import { CONFIGURATIONS } from "../../Constants/AppConstants";
import { useSelector } from "react-redux";

export type MobileActionBarType = {
  className?: string;
  activeRecording?: boolean;
  showChatPanel?: boolean;
  showParticipantsPanel?: boolean;
  showTranscriptPanel?: boolean;
  onClose?: () => void;
  toggleRecording?: () => void;
  toggleTranscriptPanel?: () => void;
  toggleParticipantsPanel?: () => void;
  toggleChatPanel?: () => void;
};

const MobileActionBar: FunctionComponent<MobileActionBarType> = ({
  className = "",
  activeRecording,
  showChatPanel,
  showParticipantsPanel,
  showTranscriptPanel,
  toggleRecording = () => {},
  toggleTranscriptPanel = () => {},
  toggleParticipantsPanel = () => {},
  toggleChatPanel = () => {},
  onClose = () => {},
}) => {
  const adminConfig = useSelector(
    (state: any) => state.LoginReducer?.configurations
  );
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );
    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const callActions = (callback: () => void) => {
    callback();
    onClose();
  };

  return (
    <div className={`mobileactionbar ${className}`} data-animate-on-scroll>
      <b className="moreoptions">{getTranslation("moreOption")}</b>
      {/*
      <div className="backgroundblurcontainer">
        <div className="backgroundblurtitleandicon">
          <div className="backgroundbluricon">
            <img className="vector-icon" alt="" src={vector} />
          </div>
          <div className="backgroundblurtext">{getTranslation("bgBlur")}</div>
        </div>
        <Switch className="switch" style={{ width: 26 }} />
      </div>
      */}
      <div className="moreoptionsseparator" />
      <div className="moreoptionsactions">
        {CONFIGURATIONS.CLIENT_SIDE_RECORDING in adminConfig && (
          <ToolBarButton
            ToolbarButtonOffIcon={VideoOffMediaControlIcon}
            toolbarButtonLabel={
              activeRecording
                ? getTranslation("stopRecording")
                : getTranslation("startRecording")
            }
            ToolbarButtonOnIcon={ChatOffMediaControlIcon}
            isOn={!activeRecording}
            toolBarButtonClick={() => callActions(toggleRecording)}
          />
        )}
        {/* {CONFIGURATIONS.SCREEN_SHARING in adminConfig && (
          <ToolBarButton
            propBorder="none"
            propBackgroundColor="transparent"
            ToolbarButtonOffIcon={ChatOffMediaControlIcon2}
            toolbarButtonLabel={getTranslation("shareScreen")}
            propColor="#121313"
            ToolbarButtonOnIcon={VideoOffMediaControlIcon1}
            isOn={false}
            // toolBarButtonClick={()=>callActions(toggleRecording)}
          />
        )} */}
        {CONFIGURATIONS.TRANSCRIPT in adminConfig && (
          <ToolBarButton
            propBorder="none"
            propBackgroundColor="transparent"
            ToolbarButtonOffIcon={ChatOffMediaControlIcon4}
            toolbarButtonLabel={getTranslation("transcription")}
            toolBarButtonClick={() => callActions(toggleTranscriptPanel)}
            propColor="#121313"
            ToolbarButtonOnIcon={VideoOffMediaControlIcon2}
            isOn={false}
          />
        )}
        {/* {CONFIGURATIONS.LIVE_CHAT in adminConfig && (
          <ToolBarButton
            propBorder="none"
            propBackgroundColor="transparent"
            ToolbarButtonOffIcon={ChatOffMediaControlIcon7}
            toolbarButtonLabel={getTranslation("chat")}
            propColor="#121313"
            ToolbarButtonOnIcon={VideoOffMediaControlIcon1}
            isOn={false}
            toolBarButtonClick={() => callActions(toggleChatPanel)}
          />
        )} */}
        <ToolBarButton
          propBorder="none"
          propBackgroundColor="transparent"
          ToolbarButtonOffIcon={ChatOffMediaControlIcon5}
          toolbarButtonLabel={getTranslation("participants")}
          toolBarButtonClick={() => callActions(toggleParticipantsPanel)}
          propColor="#121313"
          ToolbarButtonOnIcon={VideoOffMediaControlIcon3}
          isOn={false}
        />
        {/* Hide Speaker icon
        <ToolBarButton
          propBorder="none"
          propBackgroundColor="transparent"
          ToolbarButtonOffIcon={ChatOffMediaControlIcon6}
          toolbarButtonLabel={getTranslation("speaker")}
          propColor="#121313"
          ToolbarButtonOnIcon={VideoOffMediaControlIcon4}
          isOn={false}
          // toolBarButtonClick={()=>callActions(toggleRecording)}
        /> */}
      </div>
      <div className="moreoptionsseparator" />
      <button className="closecontainermoreoptions" onClick={onClose}>
        <button className="closebutton">
          <img
            className="closebuttoncontainer-icon"
            alt="ClosePopupIcon"
            src={ClosePopupIcon}
          />
        </button>
        <div className="closelabelmoreoptions">{getTranslation("close")}</div>
      </button>
    </div>
  );
};

export default MobileActionBar;
