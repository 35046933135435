import { NotificationState } from "../StateTypes/StateTypes";
import { NotificationType } from "../ActionTypes/NotificationActionTypes"
import {
    ERROR_NOTIFICATION,
    WARNING_NOTIFICATION,
    SUCCESS_NOTIFICATION,
    CLEAR_NOTIFICATIONS
} from "../ReduxConstants"

// Define the initial state for the ProConf reducer
const initialState: NotificationState = {
    errorMessage: "",
    warningMessage: "",
    successMessage: "",
};

// Reducer function to handle state changes based on actions
const NotificationReducer = (state = initialState, action: NotificationType): NotificationState => {

    switch (action.type) {
        case ERROR_NOTIFICATION:
            return { ...state, errorMessage: action.payload };

        case WARNING_NOTIFICATION:
            return { ...state, warningMessage: action.payload };

        case SUCCESS_NOTIFICATION:
            return { ...state, successMessage: action.payload };

        case CLEAR_NOTIFICATIONS:
            return { ...state, errorMessage: "", warningMessage: "", successMessage: "" }
        default:
            return state;
    }
};

export default NotificationReducer;
