import { FunctionComponent } from "react";
import "./MeetingParticipantInfo.scss";
import CustomAvatar from "../CustomAvatar/CustomAvatar";

export type MeetingParticipantInfoType = {
  className?: string;
  participantName?: string;
  participantSubtitle?: string;
};

const MeetingParticipantInfo: FunctionComponent<MeetingParticipantInfoType> = ({
  className = "",
  participantName,
  participantSubtitle,
}) => {
  return (
    <div className={`meetingparticipantinfo ${className}`}>
      <CustomAvatar name={participantName} hideName={true} size='25' />
      <div className="participanttitle">
        <b className="participantname">{participantName}</b>
        <div className="participantsubtitle">{participantSubtitle}</div>
      </div>
    </div>
  );
};

export default MeetingParticipantInfo;
