import { Navigate, Outlet } from "react-router-dom";
import { store } from "../Redux/store";

/**
 * 
 * @returns jsx compent to check if the component needs to be loaded 
 * based on the permission/authToken
 */
// @ts-ignore
export default function PrivateRoutes({ path }) {
  const authToken = store.getState()?.LoginReducer?.token;
  return authToken === null || !authToken ? (
    <Navigate replace to="/" />
  ) : (
    <Outlet />
  );
}
