//Add english strings here
const en = {
  //Errors

  //Api errors

  //SDK Messages
  maxParticipantLimit:
    "The limit for maximum number of participants has been reached. Please contact the meeting owner or try again later!",
  meetingEndedMsg: "The meeting has been terminated.",

  //Login component
  email: "Email",
  password: "Password",
  login: "Login",
  LOGIN: "LOGIN",
  OR: "OR",
  joinAsGuest: "Join as Guest",
  getStarted: "Let’s Get Started",
  emailPlaceholder: "Enter Email Address",
  passwordPlaceholder: "Enter Password",

  //Join as a guest
  yourName: "Your Name",
  enterYourName: "Enter Your Name",
  roomName: "Room Name",
  enterRoomName: "Enter Room Name",
  join: "Join",
  alreadyAccount: "Already have an account?",

  //Meeting List
  seamlessVideo: "Seamless Video Calls & Meetings for All",
  createMeeting: "CREATE MEETING",
  joinMeeting: "JOIN MEETING",
  pastMeetings: "Meeting History",
  viewSummary: "View Summary",
  enterDetails: "Please enter below details",

  //call summary
  summary: "Summary",
  discussionPoints: "Discussion Points",
  actionItems: "Action Items",
  conclusion: "Conclusion",
  listOfAttendees: "List of Attendees",

  //logout
  logout: "Logout",

  //Create Meeting
  chooseDevices: "Choose Device",
  speaker: "Speaker",
  microphone: "Microphone",
  camera: "Camera",
  start: "Start",
  videoPreview: "Video Preview",
  mic: "Mic",
  video: "Video",
  bgBlur: "Background blur",
  joinMeetingText: "Join Meeting",
  deviceInUse: "Device is already in use. Please choose another device.",

  //In-call
  presenting: "Presenting",
  meeting: "Meeting",
  you: "(You)",

  //Toolbar
  record: "Record",
  shareScreen: "Share Screen",
  stopSharing: "Stop Sharing",
  transcript: "Transcript",
  transcription: "Transcription",
  participants: "Participants",
  layout: "Layout",
  more: "More",
  endCall: "End Call",
  endForAll: "End for All",
  leaveMeeting: "Leave Meeting",
  stopTranscription: "Stop Transcription",
  hideTranscription: "Hide Transcription",
  settings: "Settings",
  moreOption: "More Options",
  close: "Close",
  systemSpeakers: "System Speakers",
  externalHeadphones: "External Headphones",
  bluetoothDevice: "Bluetooth Device",
  default: "Default",
  videoCameraDevice: "Video Camera Device",
  startRecording: "Start Recording",
  stopRecording: "Stop Recording",
  BNC: "BNC",

  //participant panel
  admin: "Admin",
  inCall: "In Call",
  waiting: "Waiting",
  invite: "Invite",
  copyLink: "Copy Link",
  copiedLink: "Copied !",
  emailInvite: "Invite via Email",
  moderator: "Moderator",
  maximimumInviteLimit: "You can invite only 4 members at a time",

  // Invite popup
  emailMembersInvite: "Invite members via Email",
  sendInvite: "Send Invite",
  emailAddr: "Email Addresses",
  message: "Message",
  inviteSuccess: "Invite sent successfully!",
  inviteError: "Error sending Invite. Please try again.",

  //404 Page
  pageNotFound: "Page Not Found",
  pageNotExists: "Oops! The page you are looking for does not exists",
  goBack: "Go Back",

  //About
  version: "Version",

  // Call Catchup
  welcome: "Welcome",
  gotLate: "Got Late?",
  briefSummary: "Here is what you missed.",
  viewCatchup: "View Catchup",
  meetingCatchup: "Meeting Catch-Up",
  proceedCall: "Proceed to call",

  //Transcript
  language: "Language",
  english: "English",
  spanish: "Spanish",
  german: "German",
  italian: "Italian",
  transcriptStarted: "Transcription Started",
  transcriptStopped: "Transcription Stopped",

  //Chat Panel
  chat: "Chat",

  // Recording
  recStarted: "Recording Started",
  recStopped: "Recording Stopped",
  recFailed: "Recording Failed",
};

export default en;
