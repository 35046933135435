import { FunctionComponent } from "react";
import "./ParticipantActionMenu.scss";
import MicOffMediaMenuIcon from "../../Resources/InCallImages/MicOffMediaMenuIcon.svg";
import VideoOffMenuIcon from "../../Resources/InCallImages/VideoOffMenuIcon.svg";
import EvictMediaControlIconSmall from "../../Resources/InCallImages/EvictMediaControlIconSmall.svg";

export type ParticipantActionMenuType = {
  className?: string;
  onClose?: () => void;
};

const ParticipantActionMenu: FunctionComponent<ParticipantActionMenuType> = ({
  className = "",
  onClose,
}) => {
  return (
    <div className={`participantactionmenu ${className}`}>
      <button className="participantactionoption">
        <img
          className="mediacontrol-icon"
          alt="MicOffMediaMenuIcon"
          src={MicOffMediaMenuIcon}
        />
        <div className="participantactionlabel">Mute Participant</div>
      </button>
      <button className="participantactionoption">
        <img
          className="mediacontrol-icon"
          alt="VideoOffMenuIcon"
          src={VideoOffMenuIcon}
        />
        <div className="participantactionlabel">Turn Off Video</div>
      </button>
      <button className="participantactionoption">
        <img
          className="mediacontrol-icon"
          alt="EvictMediaControlIconSmall"
          src={EvictMediaControlIconSmall}
        />
        <div className="participantactionlabel">Evict</div>
      </button>
    </div>
  );
};

export default ParticipantActionMenu;
