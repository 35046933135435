import { FunctionComponent } from "react";
import "./TimeandRecordIcon.scss";
import RecordingStatusIcon from "../../Resources/InCallImages/RecordingStatusIcon.svg";

export type TimeandRecordIconType = {
  className?: string;
  meetingTime?: string;
  showRecordingIcon?: boolean;
};

const TimeandRecordIcon: FunctionComponent<TimeandRecordIconType> = ({
  className = "",
  meetingTime,
  showRecordingIcon,
}) => {
  return (
    <div className={`timeandrecordicon ${className}`}>
      {showRecordingIcon && (
        <img
          className="recordingiconcontainer"
          alt=""
          src={RecordingStatusIcon}
        />
      )}
      <div className="meetingtime">{meetingTime}</div>
    </div>
  );
};

export default TimeandRecordIcon;
