import { FunctionComponent } from "react";
import "./VideoDeviceSelection.scss";
import menuicon from "../../Resources/InCallImages/menuicon1@2x.png";
import { getTranslation } from "../../Resources/Localization/i18n";
export type VideoDeviceSelectionType = {
  className?: string;
  onClose?: () => void;
};

const VideoDeviceSelection: FunctionComponent<VideoDeviceSelectionType> = ({
  className = "",
  onClose,
}) => {
  return (
    <div className={`videodeviceselection ${className}`}>
      <button className="deviceselectionoption">
        <img className="menuicon2" alt="" src={menuicon} />
        <div className="system-speakers">{getTranslation("default")}</div>
        <img
          className="selected-icon"
          alt="SelectionOptionIcon"
          src="../../Resources/InCallImages/SelectionOptionIcon.svg"
        />
      </button>
      <button className="deviceselectionoption">
        <img className="menuicon2" alt="" src={menuicon} />
        <div className="system-speakers">
          {getTranslation("videoCameraDevice")}
        </div>
        <img
          className="selected-icon1"
          alt="selectedicon"
          src="../../Resources/InCallImages/SelectionOptionIcon.svg"
        />
      </button>
    </div>
  );
};

export default VideoDeviceSelection;
