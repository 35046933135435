import {
  FunctionComponent,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import "./ActionToolBar.scss";
import ToolBarButton from "../ToolBarButton/ToolBarButton";
import PortalPopup from "../PortalPopup/PortalPopup";
import PortalDrawer from "../PortalDrawer/PortalDrawer";
import ButtonLeave from "../ButtonLeave/ButtonLeave";
import CallDeviceSelection from "../CallDeviceSelection/CallDeviceSelection";
import VideoDeviceSelection from "../VideoDeviceSelection/VideoDeviceSelection";
import MoreActionsWeb from "../MoreActionsWeb/MoreActionsWeb";
import MobileActionBar from "../MobileActionBar/MobileActionBar";
import {
  onleaveCall,
  onMicMuteUnmute,
  onNoiseCancellationToggle,
  onScreenSharing,
  onVideoMuteUnmute,
} from "../../Redux/Actions/ToolbarActions";
import { useSelector } from "react-redux";
import { getTranslation } from "../../Resources/Localization/i18n";
import { CONFIGURATIONS } from "../../Constants/AppConstants";
import { store } from "../../Redux/store";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Routes/RouteConstants";
import { isDesktopDevice } from "../../Utility/Utils";

import { ReactComponent as MicOnMediaControlIcon } from "../../Resources/InCallImages/MicOnMediaControlIcon.svg";
import { ReactComponent as MicOffMediaControlIcon } from "../../Resources/InCallImages/MicOffMediaControlIcon.svg";
import { ReactComponent as VideoOffMediaControlIcon1 } from "../../Resources/InCallImages/VideoOffMediaControlIcon1.svg";
import { ReactComponent as VideoOffMediaControlIcon5 } from "../../Resources/InCallImages/VideoOffMediaControlIcon5.svg";
import { ReactComponent as ParticpantsOnMediaControlIcon } from "../../Resources/InCallImages/ParticpantsOnMediaControlIcon.svg";
import { ReactComponent as ParticpantsOffMediaControlIcon } from "../../Resources/InCallImages/ParticpantsOffMediaControlIcon.svg";
import { ReactComponent as ScreenShareOnMediaControlIcon } from "../../Resources/InCallImages/ScreenShareOnMediaControlIcon.svg";
import { ReactComponent as ChatOffMediaControlIcon2 } from "../../Resources/InCallImages/ChatOffMediaControlIcon2.svg";
import { ReactComponent as MoreOnMediaControlIcon } from "../../Resources/InCallImages/MoreOnMediaControlIcon.svg";
import { ReactComponent as MoreOffMediaControlIcon } from "../../Resources/InCallImages/MoreOffMediaControlIcon.svg";
import { ReactComponent as EndCallMediaControlIcon } from "../../Resources/InCallImages/EndCallMediaControlIcon.svg";
// import { ReactComponent as ArrowIcon } from "../../Resources/InCallImages/arrowicon.svg";

import { ReactComponent as NoiseCancellationOffIcon } from "../../Resources/InCallImages/BNCOFF2.svg";
import { ReactComponent as NoiseCancellationOnIcon } from "../../Resources/InCallImages/BNCOn2.svg";

export type ActionToolBarType = {
  className?: string;
  activeRecording?: boolean;
  showChatPanel?: boolean;
  showParticipantsPanel?: boolean;
  showTranscriptPanel?: boolean;
  isRemoteScreenShared?: boolean;
  audioMute?: boolean;
  videoMute?: boolean;
  screenShareStarted?: boolean;
  participants?: any;
  isNoiseCancellationEnabled?: boolean;
  toggleChatPanel?: () => void;
  toggleTranscriptPanel?: () => void;
  toggleParticipantsPanel?: () => void;
  toggleRecording?: () => void;
};

const ActionToolBar: FunctionComponent<ActionToolBarType> = ({
  className = "",
  activeRecording,
  showChatPanel,
  showParticipantsPanel,
  showTranscriptPanel,
  isRemoteScreenShared,
  audioMute = false,
  videoMute = false,
  screenShareStarted = false,
  participants,
  isNoiseCancellationEnabled = false,
  toggleChatPanel,
  toggleTranscriptPanel,
  toggleParticipantsPanel,
  toggleRecording,
}) => {
  const selectDeviceIconRef = useRef<HTMLButtonElement>(null);
  const [isCallDeviceSelectionPopupOpen, setCallDeviceSelectionPopupOpen] =
    useState(false);
  const selectDeviceIcon1Ref = useRef<HTMLButtonElement>(null);
  const [isVideoDeviceSelectionPopupOpen, setVideoDeviceSelectionPopupOpen] =
    useState(false);
  const actionWrapper3Ref = useRef<HTMLButtonElement>(null);
  const [isMoreActionsWebPopupOpen, setMoreActionsWebPopupOpen] =
    useState(false);
  const actionWrapper5Ref = useRef<HTMLButtonElement>(null);
  const [isButtonLeavePopupOpen, setButtonLeavePopupOpen] = useState(false);
  const [isMobileActionBarOpen, setMobileActionBarOpen] = useState(false);
  const isModerator = useSelector(
    (state: any) => state.ParticipantReducer?.localParticipant?.isModerator
  );
  const isGuestFlow = store.getState()?.RoomReducer.userName ?? false;
  const navigate = useNavigate();
  const adminConfig = useSelector(
    (state: any) => state.LoginReducer?.configurations
  );
  const videoPermission = useSelector((state: any) => state.InCallReducer.videoPermission);
  const audioPermission = useSelector((state: any) => state.InCallReducer.audioPermission);


  useEffect(() => {
    window.addEventListener("orientationchange", closeMobileActionBar);
  }, []);

  // const openCallDeviceSelectionPopup = useCallback(() => {
  //   setCallDeviceSelectionPopupOpen(true);
  // }, []);

  const closeCallDeviceSelectionPopup = useCallback(() => {
    setCallDeviceSelectionPopupOpen(false);
  }, []);

  // const openVideoDeviceSelectionPopup = useCallback(() => {
  //   setVideoDeviceSelectionPopupOpen(true);
  // }, []);

  const closeVideoDeviceSelectionPopup = useCallback(() => {
    setVideoDeviceSelectionPopupOpen(false);
  }, []);

  const openMoreActionsWebPopup = useCallback(() => {
    setMoreActionsWebPopupOpen(true);
  }, []);

  const closeMoreActionsWebPopup = useCallback(() => {
    setMoreActionsWebPopupOpen(false);
  }, []);

  const openButtonLeavePopup = useCallback(() => {
    setButtonLeavePopupOpen(true);
  }, []);

  const closeButtonLeavePopup = useCallback(() => {
    setButtonLeavePopupOpen(false);
  }, []);

  const openMobileActionBar = useCallback(() => {
    setMobileActionBarOpen(true);
  }, []);

  const closeMobileActionBar = useCallback(() => {
    setMobileActionBarOpen(false);
  }, []);

  // Leave on end call button click for guest user or non-moderator
  const onEndToolIconClick = () => {
    if (isGuestFlow) {
      onleaveCall();
      navigate(ROUTES.ROOT_PATH);
    } else if (!isModerator) {
      onleaveCall();
      navigate(ROUTES.MEETING_LIST);
    }
    // else {
    //     onEndCallButtonClick()
    // }
  };

  return (
    <>
      <div className={`actiontoolbar-main ${className}`}>
        <div className="actionsframe">
        <div className="mediaactionwrapper">
            <ToolBarButton
              className={audioPermission ? "" : "disabled"}
              propBorder={!audioMute ? "1px solid #2a8067" : "none"}
              propBackgroundColor={!audioMute ? "#c6ead9" : "transparent"}
              ToolbarButtonOffIcon={MicOffMediaControlIcon}
              toolbarButtonLabel={getTranslation("mic")}
              propColor={!audioMute ? "#121313" : "none"}
              ToolbarButtonOnIcon={MicOnMediaControlIcon}
              isOn={!audioMute}
              toolBarButtonClick={() => onMicMuteUnmute(audioMute)}
            />

            {/* <button
              className="selectdeviceicon"
              ref={selectDeviceIconRef}
              onClick={openCallDeviceSelectionPopup}
            >
              <ArrowIcon className="arrowicon" />
            </button> */}
          </div>
          {CONFIGURATIONS.VIDEO_CALL in adminConfig && (
            <div className="mediaactionwrapper">
              <ToolBarButton
                className={videoPermission ? "" : "disabled"}
                propBorder={!videoMute ? "1px solid #2a8067" : "none"}
                propBackgroundColor={!videoMute ? "#c6ead9" : "transparent"}
                ToolbarButtonOffIcon={VideoOffMediaControlIcon5}
                toolbarButtonLabel={getTranslation("video")}
                propColor={!videoMute ? "#121313" : "none"}
                ToolbarButtonOnIcon={VideoOffMediaControlIcon1}
                isOn={!videoMute}
                toolBarButtonClick={() => onVideoMuteUnmute(videoMute)}
              />
              {/* <button
                className="selectdeviceicon"
                ref={selectDeviceIcon1Ref}
                onClick={openVideoDeviceSelectionPopup}
              >
                <ArrowIcon className="arrowicon" />
              </button> */}
            </div>
          )}
          {/* {CONFIGURATIONS.LIVE_CHAT in adminConfig && (
            <button className="actionwrapper">
              <ToolBarButton
                propBorder={showChatPanel ? "1px solid #2a8067" : "none"}
                propBackgroundColor={showChatPanel ? "#c6ead9" : "transparent"}
                ToolbarButtonOffIcon={ChatOffMediaControlIcon7}
                toolbarButtonLabel={getTranslation("chat")}
                toolBarButtonClick={toggleChatPanel}
                propColor={showChatPanel ? "#121313" : "none"}
                ToolbarButtonOnIcon={ChatOffMediaControlIcon}
                isOn={showChatPanel}
              />
            </button>
          )} */}
          <button className="actionwrapper">
            <ToolBarButton
              propBorder={showParticipantsPanel ? "1px solid #2a8067" : "none"}
              propBackgroundColor={
                showParticipantsPanel ? "#c6ead9" : "transparent"
              }
              ToolbarButtonOffIcon={ParticpantsOffMediaControlIcon}
              toolbarButtonLabel={getTranslation("participants")}
              propColor={showParticipantsPanel ? "#121313" : "none"}
              ToolbarButtonOnIcon={ParticpantsOnMediaControlIcon}
              toolBarButtonClick={toggleParticipantsPanel}
              isOn={showParticipantsPanel}
            />
          </button>
          {CONFIGURATIONS.SCREEN_SHARING in adminConfig && isDesktopDevice && (
            <button
              className="actionwrapper"
              disabled={
                isRemoteScreenShared ||
                (participants.length < 2 && !screenShareStarted)
              }
            >
              <ToolBarButton
                className={
                  isRemoteScreenShared ||
                  (participants.length < 2 && !screenShareStarted)
                    ? "disabled"
                    : ""
                }
                propBorder={screenShareStarted ? "1px solid #2a8067" : "none"}
                propBackgroundColor={
                  screenShareStarted ? "#c6ead9" : "transparent"
                }
                ToolbarButtonOffIcon={ChatOffMediaControlIcon2}
                toolbarButtonLabel={
                  screenShareStarted
                    ? getTranslation("stopSharing")
                    : getTranslation("shareScreen")
                }
                propColor="#121313"
                ToolbarButtonOnIcon={ScreenShareOnMediaControlIcon}
                isOn={screenShareStarted}
                toolBarButtonClick={() => onScreenSharing(screenShareStarted)}
              />
            </button>
          )}
          <div className="mediaactionwrapper">
            <ToolBarButton
              propBorder={
                isNoiseCancellationEnabled ? "1px solid #2a8067" : "none"
              }
              propBackgroundColor={
                isNoiseCancellationEnabled ? "#c6ead9" : "transparent"
              }
              ToolbarButtonOffIcon={NoiseCancellationOffIcon}
              toolbarButtonLabel={getTranslation("BNC")}
              propColor={isNoiseCancellationEnabled ? "#121313" : "none"}
              ToolbarButtonOnIcon={NoiseCancellationOnIcon}
              isOn={isNoiseCancellationEnabled}
              toolBarButtonClick={() =>
                onNoiseCancellationToggle(!isNoiseCancellationEnabled)
              }
            />
          </div>
          {(CONFIGURATIONS.TRANSCRIPT in adminConfig ||
            CONFIGURATIONS.CLIENT_SIDE_RECORDING in adminConfig) && (
            <button
              className="actionwrapper"
              ref={actionWrapper3Ref}
              onClick={openMoreActionsWebPopup}
            >
              <ToolBarButton
                propBorder={
                  isMoreActionsWebPopupOpen ? "1px solid #2a8067" : "none"
                }
                propBackgroundColor={
                  isMoreActionsWebPopupOpen ? "#c6ead9" : "transparent"
                }
                ToolbarButtonOffIcon={MoreOffMediaControlIcon}
                toolbarButtonLabel={getTranslation("more")}
                propColor={isMoreActionsWebPopupOpen ? "#121313" : "none"}
                ToolbarButtonOnIcon={MoreOnMediaControlIcon}
                isOn={isMoreActionsWebPopupOpen}
              />
            </button>
          )}
          <button className="actionwrapper4" onClick={openMobileActionBar}>
            <ToolBarButton
              propBorder={isMobileActionBarOpen ? "1px solid #2a8067" : "none"}
              propBackgroundColor={
                isMobileActionBarOpen ? "#c6ead9" : "transparent"
              }
              ToolbarButtonOffIcon={MoreOffMediaControlIcon}
              toolbarButtonLabel={getTranslation("more")}
              propColor={isMobileActionBarOpen ? "#121313" : "none"}
              ToolbarButtonOnIcon={MoreOnMediaControlIcon}
              isOn={isMobileActionBarOpen}
            />
          </button>
          <button
            className="actionwrapper5"
            ref={actionWrapper5Ref}
            onClick={isModerator ? openButtonLeavePopup : onEndToolIconClick}
          >
            <ToolBarButton
              propBorder="none"
              propBackgroundColor="#e25c41"
              ToolbarButtonOffIcon={EndCallMediaControlIcon}
              toolbarButtonLabel={getTranslation("endCall")}
              propColor="#fff"
              ToolbarButtonOnIcon={EndCallMediaControlIcon}
              isOn={false}
            />
          </button>
        </div>
      </div>
      {isCallDeviceSelectionPopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={selectDeviceIconRef}
          onOutsideClick={closeCallDeviceSelectionPopup}
        >
          <CallDeviceSelection onClose={closeCallDeviceSelectionPopup} />
        </PortalPopup>
      )}
      {isVideoDeviceSelectionPopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={selectDeviceIcon1Ref}
          onOutsideClick={closeVideoDeviceSelectionPopup}
        >
          <VideoDeviceSelection onClose={closeVideoDeviceSelectionPopup} />
        </PortalPopup>
      )}
      {isMoreActionsWebPopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={actionWrapper3Ref}
          onOutsideClick={closeMoreActionsWebPopup}
        >
          <MoreActionsWeb
            onClose={closeMoreActionsWebPopup}
            toggleTranscriptPanel={toggleTranscriptPanel}
            toggleRecording={toggleRecording}
            activeRecording={activeRecording}
          />
        </PortalPopup>
      )}
      {isButtonLeavePopupOpen && (
        <PortalPopup
          placement="Top right"
          relativeLayerRef={actionWrapper5Ref}
          onOutsideClick={closeButtonLeavePopup}
        >
          <ButtonLeave onClose={closeButtonLeavePopup} />
        </PortalPopup>
      )}
      {isMobileActionBarOpen && (
        <PortalDrawer
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Bottom"
          onOutsideClick={closeMobileActionBar}
        >
          <MobileActionBar
            activeRecording={activeRecording}
            showParticipantsPanel={showParticipantsPanel}
            showChatPanel={showChatPanel}
            showTranscriptPanel={showTranscriptPanel}
            onClose={closeMobileActionBar}
            toggleRecording={toggleRecording}
            toggleTranscriptPanel={toggleTranscriptPanel}
            toggleParticipantsPanel={toggleParticipantsPanel}
            toggleChatPanel={toggleChatPanel}
          />
        </PortalDrawer>
      )}
    </>
  );
};

export default ActionToolBar;
