import { FunctionComponent } from "react";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import { ReactComponent as LogoutIcon } from "../../Resources/Images/iclogout.svg";

//Styles
import "./Logout.scss";

export type LogoutType = {
    className?: string;
    onLogoutClick?: () => void;
};

const Logout: FunctionComponent<LogoutType> = ({ className = "", onLogoutClick }) => {
    return (
        <div className={`logout ${className}`}>
            <button className="optionlogout" onClick={onLogoutClick}>
                <LogoutIcon className="iclogout2-icon" />
                <div className="logout1">{getTranslation("logout")}</div>
            </button>
        </div>
    );
};

export default Logout;
