import { FunctionComponent } from "react";
import "./CallDeviceSelection.scss";
import systemSpeakers from "../../Resources/InCallImages/menuicon1@2x.png";
import selectionOptionIcon from "../../Resources/InCallImages/SelectionOptionIcon.svg";
import { getTranslation } from "../../Resources/Localization/i18n";

export type CallDeviceSelectionType = {
  className?: string;
  onClose?: () => void;
};

const CallDeviceSelection: FunctionComponent<CallDeviceSelectionType> = ({
  className = "",
  onClose,
}) => {
  return (
    <div className={`calldeviceselection ${className}`}>
      <button className="deviceselectionoption2">
        <img className="menuicon4" alt="" src={systemSpeakers} />
        <div className="system-speakers2">
          {getTranslation("systemSpeakers")}
        </div>
        <img
          className="selected-icon2"
          alt="SelectionOptionIcon"
          src={selectionOptionIcon}
        />
      </button>
      <button className="deviceselectionoption2">
        <img className="menuicon4" alt="" src={systemSpeakers} />
        <div className="system-speakers2">
          {getTranslation("externalHeadphones")}
        </div>
        <img
          className="selected-icon3"
          alt="selectedicon"
          src={selectionOptionIcon}
        />
      </button>
      <button className="deviceselectionoption2">
        <img className="menuicon4" alt="" src={systemSpeakers} />
        <div className="system-speakers2">
          {getTranslation("bluetoothDevice")}
        </div>
        <img
          className="selected-icon3"
          alt="selectedicon"
          src={selectionOptionIcon}
        />
      </button>
    </div>
  );
};

export default CallDeviceSelection;
