import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//Components
import Login from "./LoginPage";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";

//Actions
import {
  callLoginAPI,
  callJoinAsGuestnAPI,
} from "../../Redux/Actions/LoginAction";
import { clearMeetingDataFromStore } from "../../Redux/Actions/StoreAction";

//Constants
import { ROUTES } from "../../Routes/RouteConstants";
import { API_STATUS } from "../../Constants/AppConstants";

//Services
import proConfService from "../../Services/ProConfService";
import { store } from "../../Redux/store";

export default function LoginContainer() {
  const navigate = useNavigate();
  const location = useLocation();

  //states
  const [isLoading, setIsLoading] = useState(false);
  const [loginApiErrorMsg, setLoginApiErrorMsg] = useState("");
  const [joinApiErrorMsg, setJoinApiErrorMsg] = useState("");

  // Invited participant via link
  // Extract the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get the roomid and room_name query parameters from URL
  const roomnameFromURL = queryParams?.get("room_name");
  const roomId = location.pathname?.slice(1); // Removes the leading slash
  store.dispatch({ type: "SET_ROOM_ID", payload: roomId });

  useEffect(() => {
    clearMeetingDataFromStore(true);
  }, []);

  async function onLoginClick(username: string, password: string) {
    setIsLoading(true);

    let response = await callLoginAPI(username, password);

    if (response?.status === API_STATUS.SUCCESS) {
      navigate(ROUTES.MEETING_LIST);
      await proConfService.initProConf();
    } else {
      setLoginApiErrorMsg(response?.response?.data?.error?.message);
    }
    setIsLoading(false);
  }

  /*
   * Join as guest
   */
  async function onJoinClick(userName: string, roomName: string) {
    setIsLoading(true);
    let response = await callJoinAsGuestnAPI(
      userName,
      store?.getState().RoomReducer?.roomId,
      roomName
    );

    if (response?.status === API_STATUS.SUCCESS) {
      await proConfService.initProConf();
      navigate(ROUTES.MEETING_PREVIEW);
    } else {
      setJoinApiErrorMsg(response?.response?.data?.error?.message);
    }
    setIsLoading(false);
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Login
        onLoginClick={onLoginClick}
        loginApiErrorMsg={loginApiErrorMsg}
        setLoginApiErrorMsg={setLoginApiErrorMsg}
        onJoinClick={onJoinClick}
        joinApiErrorMsg={joinApiErrorMsg}
        setJoinApiErrorMsg={setJoinApiErrorMsg}
        roomnameFromURL={roomnameFromURL || ""}
      />
    </>
  );
}
