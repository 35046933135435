import { FunctionComponent, useEffect, useRef, useState } from "react";
import "./TranscriptPanel.scss";
import PanelTitleBar from "../PanelTitleBar/PanelTitleBar";
import TranscriptMessageContainer from "../TranscriptMessageContainer/TranscriptMessageContainer";
import { useSelector } from "react-redux";
import { formatMillisecondsToDate } from "../../Utility/Utils";
import { onToggleTranscription } from "../../Redux/Actions/ToolbarActions";
import { getTranslation } from "../../Resources/Localization/i18n";

export type TranscriptPanelType = {
  className?: string;
  toggleTranscriptPanel?: () => void;
};

const TranscriptPanel: FunctionComponent<TranscriptPanelType> = ({
  className = "",
  toggleTranscriptPanel,
}) => {
  const transcriptions = useSelector(
    (state: any) => state.InCallReducer?.transcriptions
  );
  const localParticipant = useSelector(
    (state: any) => state.ParticipantReducer?.localParticipant
  );
  const transcriptionEnabled = useSelector(
    (state: any) => state.ToolbarReducer?.transcriptionEnabled
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const [isNearBottom, setIsNearBottom] = useState(true);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      const isNearBottom = scrollHeight - scrollTop <= clientHeight + 100; // Adjust threshold as needed
      setIsNearBottom(isNearBottom);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (transcriptions.length > 0 && isNearBottom) {
      lastMessageRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [transcriptions, isNearBottom]);

  return (
    <div className={`transcriptpanel ${className}`}>
      <PanelTitleBar
        titleText={getTranslation("transcript")}
        toggleButton={localParticipant?.isModerator}
        // Hide languages dropdown
        // dropdown={localParticipant?.isModerator}
        onClickCloseIcon={toggleTranscriptPanel}
        checkedTranscription={transcriptionEnabled}
        onToggleTranscription={onToggleTranscription}
      />
      <div className="transcriptpanelbody" ref={containerRef}>
        {transcriptions.map((transcription: any) => {
          return (
            <TranscriptMessageContainer
              key={transcription.participantName + transcription.timestamp}
              transcriptName={transcription.participantName}
              transcriptTime={formatMillisecondsToDate(transcription.timestamp)}
              transcriptText={transcription.message}
            />
          );
        })}
        <div ref={lastMessageRef} />
      </div>
    </div>
  );
};

export default TranscriptPanel;
