import { ProConfState } from "../StateTypes/StateTypes";
import { ProConfActionTypes } from "../ActionTypes/ProConfActionTypes"
import {
    INIT_PROCONF_SUCCESS,
    CONNECT_SUCCESS,
    JOIN_ROOM_SUCCESS,
    PROCONF_ERROR,
    CLEAR_ROOM,
    IS_PROCONF_INIT,
    SET_REMOTE_SS,
} from "../ReduxConstants"

// Define the initial state for the ProConf reducer
const initialState: ProConfState = {
    proConfManager: false,
    connection: null,
    room: null,
    participants: [],
    error: null,
    isInitDone: false,
    isRemoteSSOn: false,
};

// Reducer function to handle state changes based on actions
const ProConfReducer = (state = initialState, action: ProConfActionTypes): ProConfState => {

    switch (action.type) {
        case INIT_PROCONF_SUCCESS:
            return { ...state, proConfManager: action.payload, isInitDone: true };
        case CONNECT_SUCCESS:
            return { ...state, connection: action.payload };
        case JOIN_ROOM_SUCCESS:
            return { ...state, room: action.payload };
        case PROCONF_ERROR:
            return { ...state, error: action.payload };
        case IS_PROCONF_INIT:
            return { ...state, isInitDone: action.payload };
        case SET_REMOTE_SS:
            return { ...state, isRemoteSSOn: action.payload };
        case CLEAR_ROOM:
            return { ...state, room: null, isRemoteSSOn: false };
        default:
            return state;
    }
};

export default ProConfReducer;
