import { FunctionComponent } from "react";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import CrossIcon from "../../Resources/Images/icadd21@2x.png";
import Person from "../../Resources/Images/personIcon.png";

//Utility
import { convertUTCToLocalTimezone } from "../../Utility/Utils";

//Styles
import "./MeetingSummary.scss";
import { Summary } from "../../Redux/Actions/MeetingListAction";

interface MeetingSummaryType {
  summary: Summary;
  onClose: () => void;
}

const MeetingSummary: FunctionComponent<MeetingSummaryType> = ({
  summary,
  onClose,
}) => {
  return (
    <div className={`meetingsummary `}>
      <div className="frame-container">
        <div className="summary-meeting-new-require-parent">
          <b className="summary-meeting">
            {summary?.meeting_title
              ? `${getTranslation("summary")} - ${summary.meeting_title}`
              : ""}
          </b>
          <div className="june-20-2024-parent">
            <div className="am">
              {summary?.start_time
                ? convertUTCToLocalTimezone(summary.start_time)
                : ""}
            </div>
          </div>
        </div>
        <button className="buttonsignin2">
          <img
            className="icadd2-icon1"
            alt=""
            src={CrossIcon}
            onClick={onClose}
          />
        </button>
      </div>

      <div className="frame-div">
        <div className="frame-parent1">
          {summary?.discussion_points?.length > 0 && (
            <div className="discussion-points-parent">
              <b className="discussion-points">
                {getTranslation("discussionPoints")}
              </b>
              <div className="rr-discussed-an-container">
                <ol className="rr-discussed-an-issue-with-tra">
                  {summary?.discussion_points?.map((discussionPoint, index) => {
                    return (
                      <li
                        className="rr-discussed-an"
                        key={`discussion_point-${index}`}
                      >
                        {/* @ts-ignore */}
                        {discussionPoint?.text}
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
          )}

          {summary?.action_items?.length > 0 && (
            <div className="discussion-points-parent">
              <b className="discussion-points">
                {getTranslation("actionItems")}
              </b>
              <div className="rr-discussed-an-container">
                <ol className="rr-discussed-an-issue-with-tra">
                  {summary?.action_items?.map((actionItem, index) => {
                    return (
                      <li
                        className="rr-discussed-an"
                        key={`action_item-${index}`}
                      >
                        {/* @ts-ignore */}
                        {actionItem?.text}
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
          )}

          {summary?.conclusions?.length > 0 && (
            <div className="discussion-points-parent">
              <b className="discussion-points">
                {getTranslation("conclusion")}
              </b>
              <div className="rr-discussed-an-container">
                <ol className="rr-discussed-an-issue-with-tra">
                  {summary?.conclusions?.map((conclusion, index) => {
                    return (
                      <li
                        className="rr-discussed-an"
                        key={`conslusion-${index}`}
                      >
                        {/* @ts-ignore */}
                        {conclusion?.text}
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
          )}
        </div>

        {summary?.attendees?.length > 0 && (
          <div className="list-of-attendees-parent">
            <b className="discussion-points">
              {getTranslation("listOfAttendees")}
            </b>
            <div className="participantnamesummary-parent">
              {summary?.attendees?.map((person, index) => {
                return (
                  <div
                    className="participantnamesummary"
                    key={`attendee-${index}`}
                  >
                    <div className="photo-title-summary">
                      <img
                        className="photo-title-child-summary"
                        alt="profile avatar"
                        src={Person}
                      />
                      <div className="summary-title">
                        <div className="person-name">{person}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingSummary;
